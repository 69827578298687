import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout/Layout";
import { FaUserCircle } from "react-icons/fa";
import { PageProps } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import ReactMarkdown from "react-markdown";
import { BlogDto } from "../components/BlogCard/BlogCard";
import { DateTime } from 'luxon';
import Seo from "../components/Seo";

const Heading = styled.h1`
  margin-top: 15vh;
`;

const Meta = styled.div``;

const AuthorName = styled.span`
  font-weight: 600;
  font-size: 14px;
`;
const PublishedDate = styled.span`
  color: gray;
  font-size: 14px;
`;

interface IProps extends PageProps {
  pageContext: BlogDto;
}

const SingleBlogPage = ({ pageContext }: IProps) => {
  return (
    <Layout>
       <Seo
        title={pageContext.meta_title}
        description={pageContext.meta_description}
        />
      <div className="container">
        <Heading>{pageContext.heading}</Heading>
        <Meta className="d-flex gap-2 align-items-center">
          <FaUserCircle fontSize={28} color="gray" />
          by
          <AuthorName>{pageContext.blog_author.name}</AuthorName>
          <PublishedDate>{DateTime.fromISO(pageContext.publishedAt).toFormat('dd MMM yyyy')}</PublishedDate>

        </Meta>
        <GatsbyImage
          image={pageContext.image.localFile.childImageSharp.gatsbyImageData}
          alt="single img"
          className="mt-4"
          objectFit="contain"
          objectPosition={'center'}
          style={{width:'100%'}}
        />

        <ReactMarkdown  className={"mt-5"}>
          {pageContext.content.data.content}
        </ReactMarkdown>
      </div>
    </Layout>
  );
};

export default SingleBlogPage;
