import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import { FaArrowRightLong } from "react-icons/fa6";
import { Colors } from "../../utils/colors";
import {
  GatsbyImage,
  GatsbyImageProps,
  IGatsbyImageData,
} from "gatsby-plugin-image";

const Heading = styled.h4`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  text-align: left;
  margin-top: 24px;
  letter-spacing: 0.0125em;
  color: #3d3d3d;
  @media (max-width: 575px) {
    font-size: 18px;
  }
`;

const Paragraph = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: left;
  letter-spacing: 0.0125em;
  color: #676767;
  @media (max-width: 767px) {
    padding-top: 33px;
    line-height: 28px;
    font-size: 15.8489px;
    margin-top: 0px;
  }

  @media (max-width: 575px) {
    font-size: 14px;
  }
`;

const CardWrapper = styled.div`
  background-color: ${(props) => props.color};
  padding: 20px 16px;
  border-radius: 10px;
  position: relative;

  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

  @media (max-width: 991px) {
    margin: 50px auto;
    padding: 50px 25px 25px;
    max-width: 342px;
    height: 100%;
    max-height: 500px;
  }
  @media (max-width: 575px) {
    margin-top: 50px;
    margin-bottom: 0;
    padding: 55px 12px 35px;
    height: auto;
    max-height: 100%;
  }
`;

const InnerCard = styled.div`
  color: ${(props) => props.color};
  text-align: center;
`;

const OuterDiv = styled.div`
  overflow: hidden;
  color: ${Colors.primaryColor};
  &:hover {
    color: rgb(131 91 199);
  }
`;
export interface BlogDto {
  blog_author: {
    name: string;
  };
  description: string;

  content: {
    data: {
      content: string;
    };
  };
  heading: string;
  image: {
    localFile: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
  };
  meta_title:string;
  meta_description:string;
  publishedAt: string;
  slug: string;
}
const BlogCard = ({ data }: { data: BlogDto }) => {
  function truncateParagraph(paragraph: string, wordLimit: number) {
    const words = paragraph.split(/\s+/);

    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }

    return paragraph;
  }
  return (
    <div className="col-12 col-md-6 col-lg-4 px-0 px-lg-2 m-0 mb-4 mb-md-0 ">
      <CardWrapper className="d-flex flex-column justify-content-between h-100">
        <InnerCard>
          <GatsbyImage
            alt={"blog img"}
            image={data.image.localFile.childImageSharp.gatsbyImageData}
            style={{ height: "243px" }}
          />
          <Heading>{data.heading}</Heading>
          <Paragraph>{truncateParagraph(data.description, 30)}</Paragraph>
        </InnerCard>
        <OuterDiv className="align-items-center text-center position-relative">
          <Link
            to={data.slug}
            className="align-items-end text-decoration-none d-flex align-items-center gap-2 justify-content-end"
          >
            Read More <FaArrowRightLong />
          </Link>
        </OuterDiv>
      </CardWrapper>
    </div>
  );
};

export default BlogCard;
